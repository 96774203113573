export interface ISection {
  type: SectionTypes;
  visible: boolean;
  bookButton?: boolean;
}

export interface CheckboxDto {
  type?: string;
  label: string;
  dataHook?: string;
  value: boolean;
  onChange: Function;
  infoText?: string;
  infoDataHook?: string;
}

export enum SectionTypes {
  DESCRIPTION = 'DESCRIPTION',
  TITLE_TAGLINE = 'TITLE_TAGLINE',
  CONTACT = 'CONTACT',
  DETAILS = 'DETAILS',
  POLICY = 'POLICY',
  SCHEDULING = 'SCHEDULING',
}

export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  STRETCH = 'stretch',
}

export enum SidebarPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export const enum SettingsTab {
  Manage = 'Manage',
  Layout = 'Layout',
  Sections = 'Sections',
  Design = 'Design',
  Text = 'Text',
}

export enum helpArticleIds {
  Manage = '13eff5b3-09f3-4d01-9843-efcea79592d0',
  Layout = '1aa194bf-0dab-41fe-b29a-6380ce835abe',
  Sections = 'c60312db-da04-4b03-89c6-466394e6f36d',
  Design = '38a4d79e-961d-4e33-8488-c4d8bb41e693',
  Text = '67fdf5a2-3138-476b-b922-31c59db4842c',
}

export enum MainComponents {
  HEADER = 'HEADER',
  BODY = 'BODY',
  SIDEBAR = 'SIDEBAR',
}

export enum ImagePositionOptions {
  MIDDLE = 'MIDDLE',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
}

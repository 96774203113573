import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes } from './EmptyState.st.css';

export type SchedulingEmptyStateProps = {
  message: string;
  dataHook?: string;
};
export const EmptyState: React.FC<SchedulingEmptyStateProps> = ({
  message,
  dataHook,
}) => {
  return (
    <Text className={classes.text} data-hook={dataHook || 'status-message'}>
      {message}
    </Text>
  );
};

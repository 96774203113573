import { CatalogSessionDto } from '../../types/shared-types';
import { BusinessInfo } from '@wix/bookings-uou-types';
import {
  DurationMapper,
  DurationMapperOptions,
} from '../../mappers/duration.mapper';

export interface SchedulingSectionViewModel {
  schedulingDaysViewModel?: SchedulingDayViewModel[];
  status: SchedulingSectionStatus;
}

export interface SchedulingDayViewModel {
  day: string;
  weekday: string;
  month: string;
  dailySessions: DailySessions[];
}

export interface DailySessions {
  startTime: string;
  durationText: string;
  staffName: string;
}

export enum SchedulingSectionStatus {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export const schedulingSectionViewModelFactory = ({
  catalogSessionsDto,
  businessInfo,
  isBusinessTimezone,
  t,
}: {
  catalogSessionsDto?: CatalogSessionDto[];
  businessInfo: BusinessInfo;
  isBusinessTimezone: boolean;
  t;
}): SchedulingSectionViewModel => {
  if (!catalogSessionsDto) {
    return { status: SchedulingSectionStatus.EMPTY };
  }
  const timeZone = isBusinessTimezone ? businessInfo.timeZone : undefined;
  const dateTimeFormatter = new Intl.DateTimeFormat(
    businessInfo.regionalSettingsLocale,
    {
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone,
    },
  );
  const durationMapperOptions: DurationMapperOptions = {
    hourUnit: 'duration.units.hours',
    minuteUnit: 'duration.units.minutes',
  };
  const durationMapper = new DurationMapper(
    businessInfo.regionalSettingsLocale!,
    durationMapperOptions,
    t,
  );

  const formattedSessions = catalogSessionsDto.map((sessionDto) => {
    const dateParts = dateTimeFormatter.formatToParts(
      new Date(sessionDto.startDate),
    );
    const weekday = dateParts.find(({ type }) => type === 'weekday')?.value;
    const day = dateParts.find(({ type }) => type === 'day')?.value;
    const month = dateParts.find(({ type }) => type === 'month')?.value;
    const hour = dateParts.find(({ type }) => type === 'hour')?.value;
    const minute = dateParts.find(({ type }) => type === 'minute')?.value;
    const year = dateParts.find(({ type }) => type === 'year')?.value;
    const dayPeriod = dateParts.find(
      ({ type }) => type.toLocaleLowerCase() === 'dayperiod',
    )?.value;
    const startTime = `${hour}:${minute} ${dayPeriod ?? ''}`.trimEnd();

    return {
      weekday,
      day,
      month,
      year,
      staffName: sessionDto.staffName,
      startTime,
      durationText: durationMapper.durationTextFromMinutes(
        sessionDto.durationInMinutes,
      ),
    };
  });

  return {
    schedulingDaysViewModel: formatDays(formattedSessions),
    status: SchedulingSectionStatus.SUCCESS,
  };
};

const formatDays = (formattedSessions): SchedulingDayViewModel[] => {
  const mappedDays = {};
  formattedSessions.forEach((session) => {
    const dayKey = `${session.day}-${session.month}-${session.year}`;
    mappedDays[dayKey] = mappedDays[dayKey] || initDay(session);
    mappedDays[dayKey].dailySessions.push({
      startTime: session.startTime,
      staffName: session.staffName,
      durationText: session.durationText,
    });
  });

  return Object.values(mappedDays);
};

const initDay = (session): SchedulingDayViewModel => {
  return {
    day: session.day,
    month: session.month,
    weekday: session.weekday,
    dailySessions: [],
  };
};

import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes } from './SessionDetails.st.css';

export interface SessionDetailsProps {
  startTime: string;
  duration: string;
  staffName?: string;
}
export const SessionDetails: React.FC<SessionDetailsProps> = ({
  startTime,
  duration,
  staffName,
}) => {
  return (
    <div className={classes.root}>
      <div>
        <div>
          <Text>{startTime}</Text>
        </div>
        <div>
          <Text>{duration}</Text>
        </div>
      </div>
      {staffName ? <Text>{staffName}</Text> : null}
    </div>
  );
};

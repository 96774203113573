import React from 'react';
import { InjectedTranslateProps, translate } from 'yoshi-flow-editor-runtime';
import { classes } from './SchedulingAgenda.st.css';
import { DailySessions } from './DailySessions/DailySessions';
import {
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
} from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { Loader } from './Loader/Loader';
import { EmptyState } from './EmptyState/EmptyState';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../BookingServicePage/settingsParams';

export type SchedulingAgendaProps = InjectedTranslateProps & {
  viewModel?: SchedulingSectionViewModel;
};
export const SchedulingAgendaComponent: React.FC<SchedulingAgendaProps> = ({
  viewModel,
  t,
}) => {
  const settings = useSettings();

  const componentByStatus = () => {
    switch (viewModel?.status) {
      case SchedulingSectionStatus.LOADING:
        return <Loader />;
      case SchedulingSectionStatus.EMPTY:
        return emptyState();
      case SchedulingSectionStatus.FAILED:
        return (
          <EmptyState
            message={t('app.widget.uou-messages.scheduling-error')}
            dataHook={'error-message'}
          />
        );
      case SchedulingSectionStatus.SUCCESS:
        return dailySessions();
      default:
        return <Loader />;
    }
  };
  const emptyState = () => {
    const daysAhead = settings.get(settingsParams.scheduleSessionLimit);
    return (
      <EmptyState
        message={t('app.scheduling.empty-state', { daysAhead })}
        dataHook={'empty-state'}
      />
    );
  };
  const dailySessions = () => {
    return (
      <>
        {viewModel?.schedulingDaysViewModel?.map((scheduleDay) => (
          <DailySessions viewModel={scheduleDay} />
        ))}
      </>
    );
  };
  return <div className={classes.root}>{componentByStatus()}</div>;
};

export const SchedulingAgenda = translate()(SchedulingAgendaComponent);

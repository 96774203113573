import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes } from './DayDetails.st.css';

export interface DayDetailsProps {
  day: string;
  weekday: string;
  month: string;
}
export const DayDetails: React.FC<DayDetailsProps> = ({
  weekday,
  day,
  month,
}) => {
  return (
    <div className={classes.root}>
      <div>
        <Text>{weekday},</Text>
      </div>
      <div>
        <Text>
          {month} {day}
        </Text>
      </div>
    </div>
  );
};

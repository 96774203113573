import React from 'react';
import { translate, InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import Section from '../Section';
import { ISection, SectionTypes } from '../../types';
import { SchedulingAgenda } from '../../../BookingSchedulingAgenda';
import { classes } from './Scheduling.st.css';
import SectionTitle from '../SectionTitle/SectionTitle';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import { SchedulingSectionViewModel } from '../../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';

export type SchedulingProps = InjectedTranslateProps & {
  section: ISection;
  className?: string;
  schedule?: SchedulingSectionViewModel;
};

const SchedulingComponent = ({
  t,
  section,
  className,
  schedule,
}: SchedulingProps) => {
  const settings = useSettings();
  return (
    <Section
      sectionType={SectionTypes.SCHEDULING}
      data-hook="scheduling-section"
      withBookButton={section.bookButton}
    >
      <div className={classes.agendaWrapper}>
        <SectionTitle
          title={
            settings.get(settingsParams.scheduleSectionTitle) ||
            t('app.scheduling.title.default')
          }
          className={className}
        />
        <SchedulingAgenda viewModel={schedule} />
      </div>
    </Section>
  );
};

export default translate()(SchedulingComponent);

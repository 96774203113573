import { AlignmentOptions, MainComponents } from '../types';
import { useRenderLocation } from './useRenderLocation';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../settingsParams';

export const useGlobalAlignment = (): AlignmentOptions => {
  const location = useRenderLocation();
  const settings = useSettings();
  if (location === MainComponents.BODY) {
    return settings.get(settingsParams.bodyAlignment);
  } else if (location === MainComponents.SIDEBAR) {
    return settings.get(settingsParams.sidebarAlignment);
  } else {
    return settings.get(settingsParams.headerTitleAlignment);
  }
};

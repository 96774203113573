import React from 'react';
import { classes, st } from './SectionTitle.st.css';
import { Text } from 'wix-ui-tpa/Text';
import { useRenderLocation } from '../useRenderLocation';

interface SectionTitleProps {
  title: string;
  dataHook?: string;
  className?: string;
  tagName?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  className,
  dataHook = 'section-title',
  tagName = 'h2',
}) => {
  const location = useRenderLocation();
  return (
    <Text
      data-hook={dataHook}
      className={st(classes.title, { location }, className)}
      tagName={tagName}
    >
      {title}
    </Text>
  );
};

export default SectionTitle;

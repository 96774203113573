import React from 'react';
import { classes } from './DailySessions.st.css';
import { DayDetails } from '../DayDetails/DayDetails';
import { SessionDetails } from '../SessionDetails/SessionDetails';
import {
  SchedulingDayViewModel,
  SchedulingSectionViewModel,
} from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';

export interface DailySessionsProps {
  viewModel: SchedulingDayViewModel;
}
export const DailySessions: React.FC<DailySessionsProps> = ({ viewModel }) => {
  const { weekday, day, month, dailySessions } = viewModel;
  return (
    <div className={classes.sessionsWrapper} data-hook={'daily-sessions'}>
      <div className={classes.day}>
        <DayDetails month={month} day={day} weekday={weekday} />
      </div>
      <div className={classes.sessions}>
        {dailySessions.map(({ staffName, startTime, durationText }) => (
          <SessionDetails
            startTime={startTime}
            duration={durationText}
            staffName={staffName}
          />
        ))}
      </div>
    </div>
  );
};
